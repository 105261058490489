import React, { useEffect, useState } from 'react';
import storyJsonData from '../../JsonFiles/storyData.json';
import { Link } from 'react-router-dom';
import { importImage } from '../../miscellaneousFunc';
import { getBlogsForHome } from '../../Api/blog';

export default function BrandStory() {

  // const [modifiedContent, setModifiedContent] = useState(null);

  // useEffect(() => {
  //   let words = storyJsonData.storyHead.split(' ');

  //   // Construct JSX with <br> after the first three words
  //   setModifiedContent(
  //     <>
  //       {words.slice(0, 3).join(' ')}
  //       <br />
  //       {words.slice(3).join(' ')}
  //     </>
  //   );


  // }, [])

  const [blogData, setBlogData] = useState();

  const getBlogData = async () => {
    const data = await getBlogsForHome();
    // console.log(data?.data)
    setBlogData(data?.data)
  }

  useEffect(() => {
    getBlogData();
  }, [])

  return (
    <div className="BrandStoryContainer container">
      <div className="StoryRight">
        <div className="storyImageWrapper storyTextWrapper " >

          <div className="storyDetailsWrapper storyTextDetailWrapper ">
            <h2 className='Jomolhari storyHeading ' >One more story to connect us together.</h2>
            <p className=' Inter storyUrl' >
              <Link to={"contact-us"} >Let's Connect</Link>
            </p>
          </div>


        </div>
        {blogData?.map((image, index) => (
          <div className="storyImageWrapper" key={image.id}>
            {
              <div className="storyDetailsWrapper">
                {image?.attributes?.blogTitle !== "" && (
                  <Link className="storyImgTagLink Jomolhari" to={`/blogs?blogId=${image.id}`}>{image?.attributes?.blogTitle}</Link>
                )}
              </div>
            }
            {image?.attributes?.blogTitleImageType === "gradientTypeOne" && (
              <div className={image?.attributes?.blogTitleImageType}></div>
            )}
            <img src={`${image?.attributes?.smHeroImage?.data?.attributes?.url}`} alt={`Image ${image.id}`} />
          </div>
        ))}
      </div>
      <div className="StoryRightSm">
        <div className=" storyTextWrappersm " >

          <div className=" storyTextDetailWrappersm ">
            <h2 className='Jomolhari storyHeadingsm ' >One more story<br/> to connect us together.</h2>
            <p className=' Inter storyUrl' >
              <Link to={"contact-us-sm"} >Let's Connect</Link>
            </p>
          </div>


        </div>
        <div className='StoryRightRightsm' >


          {blogData?.map((image, index) => (
            <div className="storyImageWrappersm" key={image.id}>
              {
                <div className="storyDetailsWrappersm">
                  {image?.attributes?.blogTitle !== "" && (
                    <Link className="storyImgTagLinkSm Jomolhari" to={`/blogs?blogId=${image.id}`}>{image?.attributes?.blogTitle}</Link>
                  )}
                </div>
              }
              {image?.attributes?.blogTitleImageType === "gradientTypeOne" && (
                <div className={`${image?.attributes?.blogTitleImageType}`}></div>
              )}
              <img src={`${image?.attributes?.smHeroImage?.data?.attributes?.url}`} alt={`Image ${image.id}`} className='imageSm' />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
