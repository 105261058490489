import { useAuthStore, useCartStore, useHomeStore } from "../../Store";
import data from "../../JsonFiles/products.json";
import { Link } from "react-router-dom";
import CartProduct from "./CartProduct";
import Mastercard from "../../Images/Mastercard.svg";
import Visa from "../../Images/Visa.svg";
import Paypal from "../../Images/Paypal.svg";
import { customAxios } from "../../Api/customAxios";
import { useEffect, useState } from "react";

import PaypalB from "../../Images/PaypalB.svg";
import { paypalCheckout, stripeCheckout } from "../../Api/order";
import { getCart } from "../../Api/cart";
import "../../Styles/Cart.css"

const CartItemsPage = ({ cartItemsNumber, products, cart, cartItemsPrice, setCartPage }) => {

  
  const setSidebar = useHomeStore((state) => state.setSidebar);
  const { setRedirectPath } = useHomeStore();
  
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)

  const proceedToCheckout = () =>{
    if(!isAuthenticated){
      setRedirectPath(3);
      setSidebar(2)
    }else{
      setCartPage(1)
    }
  }

  return (<div className="cart">
    {cartItemsNumber === 0 && (
      <>
        <div className="emptyCart Jomolhari">
          <h4>Your cart is Empty!</h4>
          <p>But it doesn't have to be...</p>
        </div>
        <div className="cartCarousel">
          <h4>You May Like</h4>
          <div className=" cartYouMayLike">
            {products?.map((item) => (
              <Link
                to={`/products?productId=${item?.id}`}
                className="dropdownImageElSmall"
                key={item?.id}
              >
                <img src={`${item?.attributes?.productImages[0]?.image?.data?.attributes?.url}`} alt={item?.attributes?.productName} />
                <p>{item?.attributes?.productName}</p>
              </Link>
            ))}
          </div>
        </div>
      </>
    )}
    {cartItemsNumber > 0 && (
      <>
        <div className="cartProducts">
          {cart.map(({ product, quantity }) => (
            <CartProduct
              key={
                product.productId +
                product.productSize +
                product.productColorOption
              }
              product={product}
              quantity={quantity}
              origin="cart"
            />
          ))}
        </div>
        <div className="cartBottom">
          <div className="subtotalContainer">
            <p>Subtotal:</p>
            <p>${cartItemsPrice}</p>
          </div>
          <Link to="/checkout/cart">
            <button className="goCart">Go To Cart</button>
          </Link>

          <button className="checkout" onClick={() => proceedToCheckout()} >Proceed To Checkout</button>

          <div className="accepted">
            <p>We accept:</p>
            <img src={Mastercard} alt="Mastercard" />
            <img src={Visa} alt="Visa" />
            <img src={Paypal} alt="Paypal" />
          </div>
        </div>
      </>
    )}
  </div>)
}



const PaymentSelectionPage = () => {

  const getCarts = async () => {
    const data = await getCart();
    return data
  }

  const handleStripeSubmit = async () => {
    const cartData = await getCarts();
    // console.log("Raw cart data:", cartData);

    const cartDataSending = {
      data: {
        cartItems: cartData.map((item) => ({
          id: item.id,  // Ensure `id` exists
          quantity: item.quantity,  // Ensure `quantity` exists
          product: item.product,  // Ensure `product` exists
        })),
      },
    };

    // console.log("Formatted cart data for sending:", cartDataSending);

    const data = await stripeCheckout(cartDataSending);
    // console.log(data)
    // console.log(data?.session?.url)
    if (data?.session?.url) {
      window.location.href = data?.session.url; // Redirect to stripe approval page
    }

  }
  const handlePaypalSubmit = async () => {

    const cartData = await getCarts();
    // console.log("Raw cart data:", cartData);

    const cartDataSending = {
      data: {
        cartItems: cartData.map((item) => ({
          id: item.id,  // Ensure `id` exists
          quantity: item.quantity,  // Ensure `quantity` exists
          product: item.product,  // Ensure `product` exists
        })),
      },
    };

    // console.log("Formatted cart data for sending:", cartDataSending);

    const data = await paypalCheckout(cartDataSending);
    // console.log(data)
    // console.log(data?.session?.url)
    if (data?.session?.url) {
      window.location.href = data?.session.url; // Redirect to stripe approval page
    }
  }

  return (<div className="">

    <div className="emptyCart Jomolhari">

    </div>
    <div className="">
      <h4>Choose a Payment Method</h4>

    </div>

    <button className="paypalButton" onClick={handlePaypalSubmit}>
      <img src={PaypalB} alt="paypal" />
    </button>
    <button className="goCart blackButton" onClick={handleStripeSubmit}>Stripe</button>

    <div className="accepted">
      <p>We accept:</p>
      <img src={Mastercard} alt="Mastercard" />
      <img src={Visa} alt="Visa" />
      <img src={Paypal} alt="Paypal" />
    </div>

  </div>)
}

const Cart = () => {

  const [cartPage, setCartPage] = useState(0)

  const [products, setProducts] = useState()

  const getProducts = async () => {
    try {
      const allProducts = await customAxios.get("/products?populate[productImages][populate][image]=*&populate[productImages][populate][color]=*&populate[productCategory]=*&populate[productParentCollection]=*&populate[productColorOptions][populate][colors]=*&populate[productType]=*&populate[productSize]=*&populate[productShipping]=*&populate[productReturn]=*&populate[productPackaging]=*&sort=order");
      // console.log(allProducts)
      const myproducts = allProducts?.data?.data;
      // console.log("myproducts", myproducts)
      setProducts(myproducts)

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProducts();
  }, [])

  const cart = useCartStore((state) => state.cart);
  const cartItemsNumber = useCartStore((state) => state.cartItemsNumber);
  const cartItemsPrice = useCartStore((state) => state.cartItemsPrice);

  return (
    <div>
      {cartPage === 0 && (
        <CartItemsPage
          cartItemsNumber={cartItemsNumber}
          products={products}
          cart={cart}
          cartItemsPrice={cartItemsPrice}
          setCartPage={setCartPage}
        />
      )}
      {cartPage === 1 && <PaymentSelectionPage />}
    </div>
  )

  // return (
  //   <div className="cart">
  //     {cartItemsNumber === 0 && (
  //       <>
  //         <div className="emptyCart Jomolhari">
  //           <h4>Your cart is Empty!</h4>
  //           <p>But it doesn't have to be...</p>
  //         </div>
  //         <div className="cartCarousel">
  //           <h4>You May Like</h4>
  //           <div className="searchResults ">
  //             {products?.map((item) => (
  //               <Link
  //                 to={`/products?productId=${item?.id}`}
  //                 className="dropdownImageElSmall"
  //                 key={item?.id}
  //               >
  //                 <img src={`${process.env.REACT_APP_BACKEND_URL}${item?.attributes?.productImages[0]?.image?.data?.attributes?.formats?.medium?.url}`} alt={item?.attributes?.productName} />
  //                 <p>{item?.attributes?.productName}</p>
  //               </Link>
  //             ))}
  //           </div>
  //         </div>
  //       </>
  //     )}
  //     {cartItemsNumber > 0 && (
  //       <>
  //         <div className="cartProducts">
  //           {cart.map(({ product, quantity }) => (
  //             <CartProduct
  //               key={
  //                 product.productId +
  //                 product.productSize.id +
  //                 product.productColorOption.id
  //               }
  //               product={product}
  //               quantity={quantity}
  //               origin="cart"
  //             />
  //           ))}
  //         </div>
  //         <div className="cartBottom">
  //           <div className="subtotalContainer">
  //             <p>Subtotal:</p>
  //             <p>${cartItemsPrice}</p>
  //           </div>
  //           <Link to="/checkout/cart">
  //             <button className="goCart">Go To Cart</button>
  //           </Link>
  //           <Link to="/">
  //             <button className="checkout" onClick={() => setCartPage(1)} >Proceed To Checkout</button>
  //           </Link>
  //           <div className="accepted">
  //             <p>We accept:</p>
  //             <img src={Mastercard} alt="Mastercard" />
  //             <img src={Visa} alt="Visa" />
  //             <img src={Paypal} alt="Paypal" />
  //           </div>
  //         </div>
  //       </>
  //     )}
  //   </div>
  // );
};
export default Cart;
