import React, { useEffect, useState } from "react";

import Search from "../../Icons/SearchKomhendo.svg";
import MenuIcon from "../../Icons/MenuHamburgerKomhendo.svg";
import BagIcon from "../../Icons/BagKomhendo.svg";
import UserIcon from "../../Icons/UserKomhendo.svg";
import { useCartStore, useHomeStore } from "../../Store";
import KomhendoLogo from "../../Icons/Ko.MhendoLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import { getCollections } from "../../Api/collections";

export default function NavBar() {
  const dropdowns = useHomeStore((state) => state.dropdowns);
  const setSidebar = useHomeStore((state) => state.setSidebar);
  const setDropdowns = useHomeStore((state) => state.setDropdowns);
  const cart = useCartStore((state) => state.cart);
  const cartItemsNumber = useCartStore((state) => state.cartItemsNumber);
  const cartItemsPrice = useCartStore((state) => state.cartItemsPrice);
  const navigate = useNavigate();

  useEffect(() => {
    const cartDetails = { cart, cartItemsNumber, cartItemsPrice };
    localStorage.setItem("cartDetails", JSON.stringify(cartDetails));
  }, [cart, cartItemsNumber, cartItemsPrice]);

  const [activeMenu, setActiveMenu] = useState(null);

  const handleDropdownClick = (value) => {
    if (dropdowns === value) {
      setDropdowns(0);
      setActiveMenu(null);
    } else {
      setDropdowns(value);
      setActiveMenu(value);
    }
  };

  useEffect(() => {
    if (dropdowns === 0) {
      setActiveMenu(null);
    }
  }, [dropdowns]);


  


  return (
    <div className="container navBar items-center justify-center">
      <div className="menuLeft">
        <ul className="menuLeftChildren">
          {[
            { id: 1, name: "Collections" },
            // { id: 2, name: "Pashmina" },
            { id: 3, name: "Fall / Winter 2024-25" },
          ].map((menu) => (
            <li
              key={menu.id}
              className={`menuLeftChild ${activeMenu === menu.id ? "active" : ""
                }`}
              onClick={() => { menu.id === 1 ? handleDropdownClick(menu.id) : navigate('/collection/1') }}
            >
              {menu.name}
            </li>
          ))}
        </ul>
      </div>

      <div className="container logoMid Jomolhari">
        <Link to="/">
          <img src={KomhendoLogo} className="komhendoLogoImage" alt="" />
        </Link>
      </div>
      <div className="menuRight">
        <ul className="menuRightChildren">
          {/* <li className="menuRightChild cartIcon" onClick={() => setSidebar(3)}>
            <img src={BagIcon} alt="ag" />
            {cartItemsNumber > 0 && (
              <span className="cartItemsNumber">{cartItemsNumber}</span>
            )}
          </li>
          <li className="menuRightChild" onClick={() => setSidebar(2)}>
            <img src={UserIcon} alt="user" />
          </li> */}
          <li>&nbsp; </li>
          <li>&nbsp; &nbsp; </li>
          <li>   </li>
          <li className="menuRightChild" onClick={() => handleDropdownClick(4)}>
            <img src={Search} alt="search" />
          </li>
          <li className="menuRightChild" onClick={() => setSidebar(1)}>
            <img className="menuIcon" src={MenuIcon} alt="menuIcon" />
            <span className="menuText"> &nbsp;&nbsp;Menu</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
