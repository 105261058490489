import { customAxios } from "./customAxios";

export const getProductById = async (productId) => {
  try {
    const { data } = await customAxios.get(
      `/products/${productId}?populate[productImages][populate][image]=*&populate[productImages][populate][color]=*&populate[productCategory]=*&populate[productParentCollection]=*&populate[productColorOptions][populate][colors]=*&populate[productType]=*&populate[productSize]=*&populate[productShipping]=*&populate[productReturn]=*&populate[productPackaging][populate][image]=*`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getProduct = async () => {
  try {
    const { data } = await customAxios.get(
      "/products?populate[productImages][populate][image]=*&populate[productImages][populate][color]=*&populate[productCategory]=*&populate[productParentCollection]=*&populate[productColorOptions][populate][colors]=*&populate[productType]=*&populate[productSize]=*&populate[productShipping]=*&populate[productReturn]=*&populate[productPackaging]=&*&order=sort"
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const searchProduct = async (productName) => {
  try {
    const { data } = await customAxios.get(
      `/products?filters[productName][$containsi]=${productName}&populate[productImages][populate][image]=*&populate[productImages][populate][color]=*&populate[productCategory]=*&populate[productParentCollection]=*&populate[productColorOptions]=*&populate[productType]=*&populate[productSize]=*&populate[productShipping]=*&populate[productReturn]=*&populate[productPackaging]=*`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getProductByCollection = async (id) => {
  try {
    const { data } = await customAxios.get(
      `/products?filters[productParentCollection][id][$eq]=${id}&populate[productImages][populate][image]=*&populate[productImages][populate][color]=*&populate[productCategory]=*&populate[productParentCollection]=*&populate[productColorOptions][populate][colors]=*&populate[productType]=*&populate[productSize]=*&populate[productShipping]=*&populate[productReturn]=*&populate[productPackaging]=*
  
  `
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
