import collection from "../JsonFiles/individualCollection.json";
import products from "../JsonFiles/products.json";
import "../Styles/IndividualCollection.css";
import "../Styles/CollectionItem.css";
import SlashIcon from "../Icons/SlashKomhendo.svg";
import FilterIcon from "../Icons/FilterKomhendo.svg";
import { Link, useParams } from "react-router-dom";
import CollectionItem from "../Components/CollectionItem";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter, scrollToTop } from "../miscellaneousFunc";
import { getCollectionsById } from "../Api/collections";
import { getProductByCollection } from "../Api/product";

const IndividualCollection = () => {
  let nameInUrl = useParams().name;
  // nameInUrl = nameInUrl.replace(/-/g, "").toLowerCase();
  // let displayName = useParams().name.replace(/-/g, " ");
  // displayName = capitalizeFirstLetter(displayName);

  // console.log(nameInUrl)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    windowWidth > 768 ? setLoadedItems(12) : setLoadedItems(4);
  }, [windowWidth]);


  const [collections, setCollections] = useState()
  const [collectionProducts, setCollectionProducts] = useState()

  const getMyCollections = async () => {
    const data = await getCollectionsById(nameInUrl);
    // console.log(data?.data)
    setCollections(data?.data)
    const collectionProducts = await getProductByCollection(nameInUrl);
    // console.log(collectionProducts, nameInUrl)
    // console.log("collectionProducts", collectionProducts?.data)
    setCollectionProducts(collectionProducts?.data)

  }

  // useEffect(() => {
  //   getMyCollections();
  // }, [])

  useEffect(() => {
    getMyCollections();
  }, [nameInUrl])

  const [loadedItems, setLoadedItems] = useState(12);
  const [items, setItems] = useState([]);

  // useEffect(() => {
  //   const filteredItems = products.filter((product) => {
  //     // Create a normalized format of the strings for comparison
  //     const normalize = (text) =>
  //       text
  //         .replace(/[^a-zA-Z0-9&]/g, "") // Remove unwanted characters
  //         .replace(/\s/g, "") // Remove all whitespaces
  //         .toLowerCase(); // Convert to lower case

  //     // Check if normalized product properties exactly match the normalized nameInUrl
  //     return (
  //       normalize(product.productParentCollection) === normalize(nameInUrl) ||
  //       normalize(product.productCategory) === normalize(nameInUrl) ||
  //       normalize(product.productType) === normalize(nameInUrl)
  //     );
  //   });

  //   setItems(filteredItems);
  // }, [nameInUrl]);

  const itemsEl = collectionProducts?.slice(0, loadedItems)
    .map((item, index) => (
      <CollectionItem
        key={index}
        id={item?.id}
        name={item?.attributes?.productName}
        image={item?.attributes?.productImages[0]?.image?.data?.attributes?.url}
      />
    ));

  if (collectionProducts?.length === 0) {
    return (
      <div className="container collectionItemContainer collectionComingSoon">
        <h1 style={{ margin: "auto" }} className="comingSoonText Inter" >Collection Coming Soon <br /> Stay Tuned...</h1>
        <img src="/Images/comingSoon.gif" className="comingSoonImage" alt="" />
      </div>
    );
  }

  return (
    <div className="container collectionItemContainer">
      <div
        className="heroSection"
      >
        {
          collections?.attributes?.image?.data?.length > 0 &&
          <img src={`${collections?.attributes?.image?.data[1]?.attributes?.url}`} alt="" />
        }
        {/* <h3>{category}</h3> */}
        {/* <h1 className="Jomolhari">{collections?.attributes?.collectionName}</h1> */}
        {/* <h3>
          <Link to={blogLink} className="aboutTheCollectionTag">
            About the Collection
          </Link>
        </h3> */}
      </div>
      <div className="collectionDescription">
        {/* <h2>
          <div className="categoryTitle">{category}</div>
          <img src={SlashIcon} alt="slash" style={{ marginInline: "10px" }} />
          <span className="Jomolhari"> {items.length} Designs</span>
        </h2> */}
        <p>{collections?.attributes?.description}</p>
      </div>
      <div className="collectionShowcase">
        <div className="showcaseTop">
          <h2 className="title Jomolhari">Collection: {collections?.attributes?.collectionName}</h2>
          <div className="right">
            <p className="itemsNo">{collectionProducts?.length} styles</p>
            {/* <div className="filter">
              <img src={FilterIcon} alt="filter" />
              <p>Filters</p>
            </div> */}
          </div>
        </div>

        <div className="collectionItems">{itemsEl}</div>
        <button
          type="button"
          onClick={() => setLoadedItems(loadedItems + 4)}
          className={` ${loadedItems >= collectionProducts?.length ? `hideButton` : `loadButton`
            }`}
        >
          Load more
        </button>
      </div>
    </div>
  );
};
export default IndividualCollection;
