import React, { useState, useEffect } from 'react';
import jsonData from '../JsonFiles/blogs.json';
import { useLocation } from 'react-router-dom';
import '../Styles/BlogPage.css';
import { scrollToTop } from '../miscellaneousFunc';
import ProductsCarouselSquare from '../Components/Common/ProductsCarouselSquare';
import { getBlogsById } from '../Api/blog';

export default function BlogPage() {
  // const [blogData, setBlogData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();

  // Fetch blog data based on blogId from JSON
  useEffect(() => {
    scrollToTop();
  }, []);

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const blogId = params.get('blogId');
  //   if (blogId) {
  //     const blog = jsonData.find((blog) => blog.blogId === blogId);
  //     if (blog) {
  //       setBlogData(blog);
  //     } else {
  //       setNotFound(true);
  //     }
  //   } else {
  //     setNotFound(true);
  //   }
  // }, [location.search]);

  const [blogData, setBlogData] = useState();

  const getBlogData = async (blogId) => {
    const data = await getBlogsById(blogId);
    // console.log(data?.data)
    setBlogData(data?.data)
  }

  const params = new URLSearchParams(location.search);
  
  const blogId = params.get('blogId');

  useEffect(() => {
    // console.log("Should be left aligned")
    if (blogId) {
      getBlogData(blogId);
    } else {
      setNotFound(true);
    }

  }, [])

  useEffect(() => {
    // console.log("Should be left aligned")
    if (blogId) {
      getBlogData(blogId);
    } else {
      setNotFound(true);
    }

  }, [blogId])



  if (notFound) {
    return (
      <div className="container Jomolhari d-flex items-center justify-center font-bold">
        Blog not found
      </div>
    );
  }



  return (
    <div className="blogPageContainer container">
      {blogData && (
        <>
          <div
            className={`${blogData?.attributes?.titleAndSubtitleAlign === 'left' &&
              window.innerWidth > 767
              ? 'blogPageTitleImageLeft'
              : blogData?.attributes?.titleAndSubtitleAlign === 'right' &&
                window.innerWidth > 767
                ? 'blogPageTitleImageRight'
                : 'blogPageTitleImageCenter'
              }`}
          >
            <div
              className={`${blogData?.attributes?.titleAndSubtitleAlign === 'left' &&
                window.innerWidth > 767
                ? 'blogPageTitleImgTagSmallLeft'
                : blogData?.attributes?.titleAndSubtitleAlign === 'right' &&
                  window.innerWidth > 767
                  ? 'blogPageTitleImgTagSmallRight'
                  : 'blogPageTitleImgTagSmallCenter'
                }`}
            >
              {/* {blogData?.attributes?.blogTitle} */}
            </div>
            <div
              className={`Jomolhari ${blogData?.attributes?.titleAndSubtitleAlign === 'left' &&
                window.innerWidth > 767
                ? 'blogPageTitleImgTagLeft'
                : blogData?.attributes?.titleAndSubtitleAlign === 'right' &&
                  window.innerWidth > 767
                  ? 'blogPageTitleImgTagRight'
                  : 'blogPageTitleImgTagCenter'
                }`}
            >
              {blogData?.attributes?.blogSubTitle}
            </div>
            {blogData?.attributes?.blogTitleImageType !== '' && (
              <div className={blogData?.attributes?.blogTitleImageType}></div>
            )}


            {blogData?.attributes?.heroimage?.data && (<img
              src={`${blogData?.attributes?.heroimage?.data?.attributes?.url}`}
              alt={blogData?.attributes?.blogTitle}
              className="blogTitleImage"
            />)
            }


            {blogData?.attributes?.smheroimage?.data && <img
              src={`${blogData?.attributes?.smheroimage?.data?.attributes?.url}`}
              alt={blogData?.attributes?.blogTitle}
              className="smBlogTitleImage"
            />}
          </div>
          {blogData?.attributes?.blogPageTopSlogan && (
            <div className="blogPageTopSlogan Jomolhari">
              <div className="sloganInternalSpan" dangerouslySetInnerHTML={{ __html: blogData?.attributes?.blogPageTopSlogan }}>
                {/* {blogData.blogPageTopSlogan} */}
              </div>
            </div>
          )}
          <div className="blogPageTopSection Inter">
            <div className="paragraphWrapper">
              {/* {blogData?.attributes?.blogPageTopSection.map((section) => (
                <div
                  key={section.pgId}
                  className="blogPageParagraph"
                  dangerouslySetInnerHTML={{ __html: section.pgDesc }}
                ></div>
              ))} */}
              <div
                className="blogPageParagraph"
                dangerouslySetInnerHTML={{ __html: blogData?.attributes?.blogPageTopSection }}
              ></div>
            </div>
          </div>
          {/* <div className="blogPageMidContentImage"> */}
          {/* <img
              src={blogData.blogPageMidContentImage}
              alt={blogData.blogTitle}
              className="blogPageMidContentImageLg"
            />
            <img
              src={blogData.smBlogPageMidContentImage}
              alt={blogData.blogTitle}
              className="smBlogPageMidContentImage"
            /> */}
          {/* 
          </div> */}
          <ProductsCarouselSquare
            images={blogData?.attributes?.images?.data}
          />
          <div className="blogPageMidContentSection Inter">
            <div className="paragraphWrapper">
              {/* {blogData.blogPageMidSection.map((section) => (
                <div
                  key={section.pgId}
                  className="blogPageParagraph"
                  dangerouslySetInnerHTML={{ __html: section.pgDesc }}
                ></div>
              ))} */}
              <div
                className="blogPageParagraph"
                dangerouslySetInnerHTML={{ __html: blogData?.attributes?.blogPageMidSection }}
              ></div>
            </div>
          </div>
          {blogData?.attributes?.blogPageMidPageSlogan && (
            <div className="blogPageMidPageSlogan Jomolhari">
              <div className="sloganInternalSpan">
                {blogData?.attributes?.blogPageMidPageSlogan}
              </div>
            </div>
          )}
          {/* {blogData.blogPageBottomSection && (
            <div className="blogPageBottomContentSection Inter">
              <div className="paragraphWrapper">
                {blogData.blogPageBottomSection.map((section) => (
                  <div key={section.pgId} className="blogPageParagraph">
                    {section.pgDesc}
                  </div>
                ))}
              </div>
            </div>
          )} */}
        </>
      )}
    </div>
  );
}
